import React from 'react';
import {Dropdown, Pagination} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

import useOrderSearch from "@/common/hooks/useOrderSearch";
import {OrderApiPageResponse} from "@/common/models/api/v0/order.dto";
import {Statuses} from "@/common/constants/statuses";
import SearchableSelect from "@/pages/order/components/SearchableSelect";
import OrderService from "@/common/api/OrderService";
import {orderStatusMap} from "@/components/controls/order-action/actions";

interface SelectBarProps {
    data: OrderApiPageResponse,
    controlPage: {
        page: number,
        nextPage: () => void,
        previousPage: () => void,
    },
    onEdit: () => void
    showAlert: (message: string, isError?: boolean) => void
}


const SelectBar = observer(({data, controlPage, showAlert, onEdit}: SelectBarProps) => {
    const {selectedOrders, setSelectedOrders} = useOrderSearch()
    const navigate = useNavigate()

    const handlePrintStickers = () => {
        const unmarkedOrders = selectedOrders.filter(order => !Statuses.printAccessibleStatuses.includes(order?.status))
        if(unmarkedOrders.length > 0) {
            showAlert("Промаркируйте все выбранные доставки для печати наклеек")
        } else {
            navigate('/order-stickers-print', {state: {
                    orderItems: JSON.stringify(selectedOrders)
                }})
        }
    }

    const handleCouriersSearch = async (selectedOption) => {
        let improperStatusOrders = selectedOrders.filter(orderItem => !Statuses.courierChangeStatuses.find(status => status === orderItem?.status))
        if(improperStatusOrders.length > 0) {
            showAlert("Выбранные доставки имеют некорректный статус")
        } else {
            let courier;
            if (typeof selectedOption === "string") {
                courier = selectedOption;
            } else {
                courier = selectedOption.value;
            }
            try {
                await assignCouriers(courier)
                showAlert("Курьеры успешно назначены", false)
                onEdit()
            } catch (e) {
                console.log(e)
                showAlert("Ошибка сервера")
            }
        }
    }

    const assignCouriers = async (courier) => {
        for (const orderItem of selectedOrders) {
            let updatedOrderItem = {...orderItem, courier: courier}
            await OrderService.updateOrderItem(updatedOrderItem, orderItem?.id)
            if(Statuses.courierAssignableStatuses[orderItem?.status]){
                updatedOrderItem = {...updatedOrderItem, status: Statuses.courierAssignableStatuses[orderItem?.status]}
                await OrderService.changeStatus(orderItem?.id, orderStatusMap[orderItem?.status]?.events[0].event)
            }
        }
    }
    const resetSelect = () => {
        setSelectedOrders([])
    }
    return (
        <>
            {data && data.orders?.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">

                    <Pagination size="sm" className={`${controlPage?.page === 1 && 'mx-3'}`}>
                        {controlPage?.page !== 1 &&
                            <Pagination.Prev
                                onClick={controlPage?.previousPage}
                            />
                        }
                        <Pagination.Item active>{controlPage?.page}</Pagination.Item>
                        {(!data.isLast && controlPage?.page < data.totalPages) && (
                            <Pagination.Next
                                onClick={controlPage?.nextPage}
                            />
                        )}
                        <div className="d-flex align-items-center px-3">
                            <span>{data.left} из {data.total}</span>
                        </div>
                    </Pagination>
                    {
                        (selectedOrders?.length > 0) && (<>
                            <div className="d-flex flex-column align-items-center">
                                <p className="heading-4 m-0">Выбрано {selectedOrders?.length}</p>
                                <p className="heading-4 m-0 text-primary cursor" onClick={resetSelect}>Сбросить все</p>
                            </div>
                            <div className="d-flex">
                                <SearchableSelect
                                    orderItem={selectedOrders[0]}
                                    menuPlacement={'top'}
                                    placeHolderOnly={true}
                                    readOnly={!(selectedOrders.length > 0)}
                                    onChange={handleCouriersSearch}
                                />
                                <Dropdown className={"mx-2"}>
                                    <Dropdown.Toggle
                                        className="border-rounded-1"
                                        disabled={!(selectedOrders.length > 0)}
                                    >
                                        Печать
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-0 mb-1">
                                        <Dropdown.Item
                                            className="py-2 border-rounded-1 text-center"
                                            onClick={handlePrintStickers}
                                        >
                                            Наклеек
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </>)
                    }
                </div>
            )}
        </>
    );
})

export default SelectBar;