import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Alert, Button} from 'react-bootstrap'
import {Funnel, FunnelFill, XSquare} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

import {OrderSearchFilter} from '@/common/models/order'
import { OrderApiPageResponse } from "@/common/models/api/v0/order.dto"
import OrderService from '@/common/api/OrderService'
import FilterBar from './components/FilterBar'
import OrdersView from './components/OrdersView'
import SearchBar from './components/SearchBar'
import useAuth from '@/common/hooks/useAuth'
import usePage from '@/common/hooks/usePage'
import useDebounce from "@/common/hooks/useDebounce";
import {buildFetchOrdersQuery} from "@/common/utils/utils";
import OrderItemSort from "@/pages/order/components/OrderItemSort";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import DefaultButton from '@/components/buttons/DefaultButton'
import OrdersTabs from "@/pages/order/components/OrdersTabs";
import SelectBar from "@/pages/order/components/SelectBar";

/*
 * TODO: The page has an excessive render issues. Refactoring and decomposition needed.
*/
const OrderViewPage = () => {
    const {user} = useAuth()
    const {page, nextPage, previousPage, currentPage, resetPage} = usePage()
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState({
        show: false,
        message: "",
        isError: false
    })

    const { persistedFilterQuery, persistFilterQuery, selectedOrders, setSelectedOrders } = useOrderSearch()
    const [filterQuery, setFilterQuery] = useState<OrderSearchFilter | {}>(persistedFilterQuery)

    const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

    const [searchType, setSearchType] = useState<string>('orderItemId')
    const [searchQuery, setSearchQuery] = useState<string>('')

    const {data, isFetching, error, refetch} = useQuery<OrderApiPageResponse>(['orders', currentPage, filterQuery],
        () => OrderService.getAll(buildFetchOrdersQuery(searchQuery, searchType, filterQuery, currentPage)))

    const refetchWithDebounce = useDebounce(refetch, 800)
    const resetPageWithDebounce = useDebounce(resetPage, 200)

    const handleSearchOptionChange = async (option: string) => {
        setSearchType(() => option)
    }

    const handleSearchInputChange = async (value: string) => {
        setSearchQuery(() => value)
        await resetPageWithDebounce()
        refetchWithDebounce()
    }

    const handleAnyChange = () => {
        refetch()
    }

    const handleShowAlert = (message, isError = true) => {
        setShowAlert({
            show: true,
            message: message,
            isError: isError
        })
    }

    const handleCloseAlert = () => {
        setShowAlert({
            show: false,
            message: "",
            isError: false
        })
    }

    useEffect(() => {
        if(showAlert.show){
            setTimeout(() => {
                    setShowAlert({
                        show: false,
                        message: "",
                        isError: false
                    })
            }, 5000)
        }
    }, [showAlert])

    useEffect(() => {
        setSelectedOrders(selectedOrders.map(selectedOrder => {
            return data?.orders?.find(refreshedOrder => refreshedOrder?.id === selectedOrder?.id) ?? selectedOrder
        }))
    }, [data, selectedOrders, setSelectedOrders])

    return (

            <div className="pt-3">
                {/* Header */}
                <div className={"d-flex justify-content-between"}>
                    <p className="heading-2">Заказы</p>
                    {showAlert.show && (
                        <Alert variant={showAlert.isError ? "danger" : "success"} className="d-flex align-items-center m-0" style={{padding: "0.5rem"}} >
                            <Alert.Heading className={"btn-text m-0"}>{showAlert?.message}</Alert.Heading>
                            <div style={{paddingLeft: "1rem", cursor: "pointer"}}
                                 onClick={() => handleCloseAlert()}
                            >
                                <XSquare style={{width: "20px", height: "20px"}}/>
                            </div>
                        </Alert>
                    )}
                </div>

                {/* Parameters */}
                <div className="border border-rounded-1 my-1 p-3">
                    <div className="d-flex">
                        <SearchBar
                            role={user.roles[0]}
                            className="flex-grow-1"
                            onSelect={handleSearchOptionChange}
                            onChange={handleSearchInputChange}
                        />
                        <div className="px-1"></div>
                        <Button
                            className="d-flex border align-items-center border-rounded-1"
                            variant="outline-light"
                            size="sm"
                            onClick={() => setIsFilterActive(!isFilterActive)}
                        >
                            {isFilterActive ? (
                                <FunnelFill color="dark"/>
                            ) : (
                                <Funnel color="dark"/>
                            )}
                        </Button>
                    </div>
                    {isFilterActive && (
                        <div className="mt-1">
                            <div className="py-2">
                                <span className="heading-font">Фильтры</span>
                            </div>
                            <FilterBar
                                onChange={(option) => {
                                    setFilterQuery({...option})
                                    persistFilterQuery({...option})
                                    resetPage()
                                }}
                            />
                        </div>
                    )}
                </div>

                {/* Order list */}
                <div>
                    <div className={"mx-3 mt-2 mb-2"}>
                        <OrdersTabs
                            key={persistedFilterQuery?.deliveryStatuses}
                            onChange={(option) => {
                                setFilterQuery({...option})
                                persistFilterQuery({...option})
                                resetPage()
                            }}
                        />
                    </div>
                    <div className={`d-flex justify-content-start align-items-center mb-3`}>

                        <OrderItemSort/>
                        {data && data.orders?.length > 0 
                            && searchType === 'courier'
                            && (
                            <div className="ms-auto">
                                <DefaultButton
                                    onClick={() => {
                                        navigate('/orders-list-print', { state: { orders: data.orders } })
                                    }}
                                    variant="light"
                                    bordered
                                >
                                    Печать реестра
                                </DefaultButton>
                            </div>
                        )}
                    </div>

                    <div style={{overflowY: 'auto', height: isFilterActive ? '52dvh' : '67dvh'}}>
                        <OrdersView
                            role={user.roles[0]}
                            data={data ? data.orders : []}
                            isLoading={isFetching}
                            error={error}
                            onAnyChange={handleAnyChange}
                        />
                    </div>
                    <SelectBar
                        data={data}
                        controlPage={{page, nextPage, previousPage}}
                        showAlert={handleShowAlert}
                        onEdit={refetch}
                    />

                </div>
            </div>
    )
}

export default OrderViewPage
