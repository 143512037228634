import { Spinner } from "react-bootstrap"
import { AsyncPaginate } from "react-select-async-paginate"

import CourierService from "@/common/api/CourierService"
import {OrderItem} from "@/common/models/order";
import {SelectOption} from "@common/models/util";

const SearchableSelect = ({ onChange = () => {}, isLoading, placeHolderOnly = false, menuPlacement = 'auto', readOnly = true, orderItem, error }: SearchableSelectProps) => {
	const buildSelectOption = item => ({
        value: item,
        label: `${item?.lastName} ${item?.firstName}`,
    })

    const loadOptions = async (_search: any, _loadedOptions: any, { page }) => {
        const response = await CourierService.getAll({page})
        return {
            options: response.couriers.map(buildSelectOption),
            hasMore: !response.isLast,
            additional: {
                page: page + 1,
            }
        }
    }

	if (isLoading) {
    	return (
        	<div className="d-flex flex-column justify-content-center align-items-center h-100">
            	<Spinner />
                <span className="heading-2 my-3">Получение ...</span>
            </div>
        )
    }

	return (
        <AsyncPaginate
            defaultOptions
            loadOptions={loadOptions}
            className="basic-single"
            menuPlacement={menuPlacement}
            classNamePrefix="select"
            defaultValue={orderItem?.courier && !placeHolderOnly ? buildSelectOption(orderItem?.courier) : {value: "", label: "Назначить курьера..."}}
            placeholder={"Назначить курьера..."}
            isDisabled={readOnly}
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            name="courier"
            onChange={onChange}
            additional={{
                page: 0,
            }}
        />
      );
}

interface SearchableSelectProps {
    isLoading?: boolean,
    readOnly?: boolean,
    orderItem: OrderItem,
    placeHolderOnly?: boolean,
    menuPlacement?: 'auto' | 'top',
    error?: any,
    onChange: (selectedOption: string | SelectOption) => void,
}
export default SearchableSelect